import React, { useEffect } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import UIkit from "uikit"
import Headings from "./headings"
import DOMPurify from "dompurify"

const SliderSwitcherContent = ({
  sliderSwitcherContent,
  title,
  description,
  bg = "uk-background-default",
}) => {
  //console.log("sliderSwitcherContent: ", sliderSwitcherContent)
  const [hasMounted, setHasMounted] = React.useState(false)

  useEffect(() => {
    // trigger another render, reconciliation after the first mount, updating the dom with regards to rehydration
    setHasMounted(true)

    // now everything is syncned
    const util = UIkit.util

    // init switcher
    const switchEl = UIkit.switcher(".uk-subnav", {
      animation: "uk-animation-fade",
      swiping: false,
    })

    const sliderEl = UIkit.slider("[uk-slider]")

    // get slider elements
    const sliderItems = util.$$(".uk-slider-items li")

    // add active class to first slider element
    util.addClass(sliderItems[0], "active")

    // add event listener for each element
    sliderItems.forEach((item, index) => {
      UIkit.util.on(item, "itemshow", function () {
        switchEl.show(index)
        sliderEl.show(index)
        util.removeClass(sliderItems, "active")
        util.addClass(item, "active")
      })
      // go to switcher index based on slider element index
      item.addEventListener("click", () => {
        switchEl.show(index)
        //sliderEl.show(index);
        util.removeClass(sliderItems, "active")
        util.addClass(item, "active")
      })
    })
  }, [])

  if (!hasMounted) {
    setHasMounted(true)
  }

  return (
    <div className={`uk-section ${bg} uk-padding-remove`}>
      <div
        className="uk-container uk-margin-small-top"
        style={{ marginBottom: "20px" }}
      >
        <div className="uk-container uk-section-small uk-padding-remove">
          <Headings title={title} description={description} bg={bg} />
          <div
            className="uk-position-relative uk-visible-toggle"
            uk-slider="autoplay:true;"
          >
            <ul className="uk-slider-items uk-grid uk-grid-small uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-5@m">
              {sliderSwitcherContent.map((item, index) => {
                //console.log(item.image.localFile)
                return (
                  <li key={index}>
                    {item.image && (
                      <GatsbyImage
                        image={getImage(
                          item.image.localFile.childImageSharp.gatsbyImageData
                        )}
                        alt={item.image.alternativeText}
                        title={item.image.caption}
                        style={{ maxHeight: "100%", borderRadius: "10px" }}
                      />
                    )}
                    {/* <div className="uk-position-center uk-panel">
                      <h1>{item.sequence}</h1>
                    </div> */}
                  </li>
                )
              })}
            </ul>

            <a
              className=" uk-slidenav-large uk-position-center-left uk-position-small uk-hidden-hover uk-visible@m"
              href="/#"
              aria-label="Previous"
              uk-slidenav-previous="true"
              uk-slider-item="previous"
              //style={{ stroke: "aqua", strokeWidth: "4" }}
              //style={{ width: "20px", height: "20px" }}
              style={{ color: "#ff7f00" }}
            >
              {null}
            </a>
            <a
              className="uk-position-center-left uk-position-small uk-hidden-hover uk-hidden@m"
              href="/#"
              aria-label="Previous"
              uk-slidenav-previous="true"
              uk-slider-item="previous"
              //style={{ stroke: "aqua", strokeWidth: "4" }}
              //style={{ width: "20px", height: "20px" }}
              style={{ color: "#ff7f00" }}
            >
              {null}
            </a>
            <a
              className="uk-slidenav-large uk-position-center-right uk-position-small uk-hidden-hover uk-visible@m"
              href="/#"
              aria-label="Next"
              uk-slidenav-next="true"
              uk-slider-item="next"
              style={{ color: "#ff7f00" }}
            >
              {null}
            </a>
            <a
              className="uk-position-center-right uk-position-small uk-hidden-hover uk-hidden@m"
              href="/#"
              aria-label="Next"
              uk-slidenav-next="true"
              uk-slider-item="next"
              style={{ color: "#ff7f00" }}
            >
              {null}
            </a>
          </div>
          <hr />
          <div>
            <ul className="uk-subnav uk-hidden">
              <li>
                <a href="/">Item</a>
              </li>
              <li>
                <a href="/#">Item</a>
              </li>
              <li>
                <a href="/#">Item</a>
              </li>
              <li>
                <a href="/#">Item</a>
              </li>
              <li>
                <a href="/#">Item</a>
              </li>
              <li>
                <a href="/#">Item</a>
              </li>
            </ul>

            <ul className="uk-switcher uk-margin">
              {sliderSwitcherContent.map((item, index) => {
                //console.log(item.image.localFile)
                //console.log("item: ", item)

                return (
                  <li key={index}>
                    <div className="pooche-card">
                      <div className="uk-card uk-card-body uk-card-default uk-text-center">
                        {/* <div className="gradient-text-title ">{item.title}</div> */}
                        <h4 className="uk-margin-remove-bottom uk-margin-remove-top">
                          <span className="orange-grad">{item.title}</span>
                        </h4>
                        <p className="uk-text-left">
                          {item.description.data.description}
                        </p>
                        {item.enabled ? (
                          <Link
                            to={item.link}
                            title="text"
                            alt="text"
                            className="uk-button uk-button-default uk-button-small uk-text-small "
                            style={{
                              borderColor: "#617F7F",
                              color: "#617F7F",
                              borderRadius: "8px",
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              marginBottom: "20px",
                            }}
                          >
                            <span
                              className="uk-visible@s"
                              style={{
                                textTransform: "none",
                                fontSize: "16px",
                              }}
                            >
                              {item.buttonName}
                              <span
                                className="uk-margin-small-left"
                                uk-icon="icon: arrow-right; ratio: 1"
                              ></span>
                            </span>

                            <span
                              className="uk-hidden@s"
                              style={{
                                textTransform: "none",
                              }}
                            >
                              {item.buttonName}
                              <span
                                className="uk-margin-small-left"
                                uk-icon="icon: arrow-right; ratio: 1"
                              ></span>
                            </span>
                          </Link>
                        ) : (
                          <div className="uk-text-center uk-margin-small-top">
                            {item.paypalButton ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(
                                    `${item.paypalButton}`,
                                    {
                                      sanitize: true,
                                    }
                                  ),
                                }}
                              />
                            ) : null}
                          </div>
                        )}
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SliderSwitcherContent
