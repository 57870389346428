import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Moment from "react-moment"

const ArticleCard = ({ article }) => {
  //console.log("Article Card: ", article)
  return (
    <div className="pooche-card" style={{ padding: "10px" }}>
      <div
        className="uk-card uk-card-default uk-card-small uk-border-rounded"
        style={{ height: "100%" }}
      >
        <div className="uk-card-media-top">
          <Link to={`/article/${article.slug}`}>
            {article?.cover && (
              <GatsbyImage
                image={getImage(article.cover.localFile)}
                alt={article.cover.alternativeText}
                title={article.cardTitle}
                data-uk-img="target: !.uk-slideshow-items"
              />
            )}
          </Link>
        </div>
        <div className="uk-card-header">
          <div className="uk-grid-small uk-flex-middle" data-uk-grid="true">
            <div className="uk-width-auto">
              {article?.author?.avatar && (
                <GatsbyImage
                  image={getImage(article.author.avatar.localFile)}
                  alt={article.author.avatar.alternativeText}
                  title={article.author.name}
                  className="uk-border-circle"
                  style={{ width: "50px", height: "50px" }}
                />
              )}
            </div>
            <div className="uk-width-expand">
              <span>
                <h6
                  className="uk-margin-remove-bottom uk-text-bold"
                  style={{ color: "#617F7F" }}
                >
                  {article.author.name}
                </h6>
              </span>
              <p className="uk-text-meta uk-margin-remove-top uk-text-small">
                <Moment format="MMMM Do YYYY">{article.publishedAt}</Moment>
              </p>
            </div>
          </div>
        </div>
        <div className="uk-card-body">
          <h4 className="uk-margin-small-bottom uk-visible@m">
            {article.cardTitle}
          </h4>
          <h5 className="uk-margin-remove-top uk-margin-small-bottom green-grad uk-hidden@m">
            {article.cardTitle}
          </h5>
          <p className="uk-text-small uk-margin-remove-top">
            {article.shortDesc}
          </p>
          <Link
            className="hover-underline-animation-dark green-grad"
            //style={{ color: "#617F7F", textDecoration: "none" }}
            to={`/article/${article.slug}`}
          >
            <h5 className="uk-margin-remove">Read more</h5>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ArticleCard
